<div>
    
    <!-- INTRO -->
    <div>

        <!-- CONFIRMATION NUMBER -->
        <p>
            Confirmation No:
            <span class="font-semibold">
                {{ order.order_id}}
            </span>
        </p>
        
        <br>
    
        <!-- TEXT -->
        <p>
            Dear {{ order.first_name}} {{ order.last_name}}, <br>
            The following is your summary for supporter number {{ order.account}}. 
            <br> <br>
            You have purchased:
            <br>
        </p>

        <br>

    </div>

    <!-- SEPARATOR -->
    <hr>
    
    <!-- DETAILS -->
    <ng-container *ngIf="order.seat_transactions else MembershipDetail">

        <div  
            *ngFor="let transaction of order.seat_transactions"
            class="py-2"
        >
            <h5 class="font-semibold text-main">
                {{ transaction.event_name || transaction.package_name }}
            </h5>
    
            <p class="text-secondary font-medium">                
                    {{ transaction.event_venue || transaction.package_venue}}
                &nbsp;
                <span class="text-darkgray">
                    {{ transaction.event_date ?? null }}
                </span>
            </p>
    

            <!-- SALE -->
            <ng-container *ngIf="orderType !== 'Exchange'">
    
    
                <div class="mt-2" *ngFor="let customer of transaction.customers">
    
                    <p class="mb-2">
                        Supporter 
                        <span class="font-medium">
                            {{ customer.name }}
                        </span>
                    </p>
    
                    <ng-container *ngFor="let buyerTypes of customer.buyer_type">
                                           
                            <div *ngFor="let seat of buyerTypes.seats" class="flex bg-gray-50 shadow-md p-2 justify-between rounded-lg pb-2 relative mb-6">
                                
                                <p class="border-r border-lightgray w-1/3 text-center text-secondary font-semibold">
                                    <span class="text-darkgray font-medium block">
                                        Section
                                    </span>
                                    {{ seat.tdc_section}}
                                </p>
    
                                <p class="w-1/3 text-center text-secondary font-semibold">
                                    <span class="text-darkgray font-medium block">
                                        Row
                                    </span>
                                    {{ seat.tdc_seat_row}}
                                </p>
    
                                <p class="border-l border-lightgray w-1/3 text-center text-secondary font-semibold">
                                    <span class="text-darkgray font-medium block">
                                        Seat
                                    </span>
                                    {{ seat.tdc_seat_number}}
                                </p>
    
                                <div class="priceLabel absolute bg-secondary flex justify-center items-end h-8 rounded-full w-1/2 left-0 bottom-0 -z-10">
                                    <p class="text-white font-medium text-xs">
                                        Price:
                                        <span>
                                            {{ buyerTypes.unit_price | currency:currency }}
                                        </span>
                                    </p>
                                </div>
                            </div>
    
                    </ng-container>
    
                </div>
                
    
            </ng-container>
    
           
        </div>

    </ng-container>

    <!-- MEMBERSHIP DETAIL -->
    <ng-template #MembershipDetail>
        <div class="my-4">
            <p class="">
                Supporter 
                <span class="font-medium">
                    {{ membershipOrder.customer }}
                </span>
            </p>
    
            <p class="text-secondary font-medium mb-2">
                {{ membershipOrder.price_scale}}
            </p>
    
            <p class="font-semibold text-main">
                {{ membershipOrder.event_name}}
            </p>
            
            <p class="text-darkgray font-medium text-xs">
                {{ membershipOrder.buyer_type}}
            </p>

        </div>
    </ng-template>

    <!-- SEPARATOR -->
    <hr>

    <!-- TOTAL -->
    <div class="flex justify-between mt-4">
      
        <div class="flex flex-col w-1/3">
            <p class="flex justify-between">
                Ticket Total: 
                <span class="text-darkgray">
                    {{ order.total_ticket| currency:currency }}
                </span>
            </p>
            <p class="flex justify-between">
                Service Charges: 
                <span class="text-darkgray">
                    {{ order.ticket_service_charges| currency:currency }} 
                </span>
            </p>
        </div>
        
        <p class="text-darkgray font-medium text-right">
            Total
            <br>
            <span class="text-secondary font-semibold text-lg">
                {{ order.total_ticket| currency:currency }}
            </span>
        </p>
    </div>

</div>
import { Component, inject, Input } from '@angular/core';
import { ExchangeOrder, MembershipOrder, Order, PackageOrder, ReservationOrder, SaleOrder } from 'src/app/shared/models/order.model';
import { APP_CONFIG } from 'src/configuration/configuration';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrl: './order-detail.component.css'
})
export class OrderDetailComponent {

  // INPUTS & OUTPUTS
  @Input() public order!:      Order | ReservationOrder | SaleOrder | PackageOrder | ExchangeOrder | MembershipOrder;
  @Input() public orderType!: 'Sale' | 'Exchange' | 'Reservation' | 'Package' | 'Membership';

  // CONFIG
  protected currency: string = inject(APP_CONFIG).general.currency;

  // GETTERS
  public get membershipOrder(): MembershipOrder {
    return this.order as MembershipOrder;
  }

}

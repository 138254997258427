import { Component, computed, inject, OnInit, signal, Signal, ViewChild, WritableSignal } from '@angular/core';
import { HistoryService } from 'src/app/core/services/order.service';
import { OrderDetail, OrderHistory } from './order-history.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modal.service';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { Order } from 'src/app/shared/models/order.model';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrl: './order-history.component.css'
})
export class OrderHistoryComponent implements OnInit {

  @ViewChild('orderView', { static: false }) 
  orderView!: SwalComponent;

  // SERVICES
  protected orderHistoryService: HistoryService    = inject(HistoryService);
  protected swalTargets:         SwalPortalTargets = inject(SwalPortalTargets)
  private   modalService:        ModalService      = inject(ModalService);

  // STATE
  public orders:       Signal<Map<string, Order>>        = this.orderHistoryService.orders;
  public orderHistory: Signal<OrderHistory | undefined>  = this.orderHistoryService.orderHistory;
  public page:         Signal<number>                    = this.orderHistoryService.page;
  public tickets:      Signal<OrderDetail[]>             = this.orderHistoryService.tickets;
  
  // ORDER
  public order:        WritableSignal<Order | undefined> = signal(undefined);
  public orderType:    Signal<'Sale' | 'Exchange' | 'Reservation' | 'Package'| 'Membership' | undefined> = computed(()=> {
      
    if(!this.order()){
      return undefined;
    }

    const key: string = this.order()!.order_id.split('_')[0];
    switch(key){
      case 'S':
        return 'Sale';
      case 'E':
        return 'Exchange';
      case 'P':
        return 'Package';
      case 'R':
        return 'Reservation';
      case 'M':
        return 'Membership';
      default:
        return undefined;
    }


  })


  // DATES
  /**
   * Emite las fechas mínima y máxima de los tickets en formato ISO.
   * @returns {minDate: string, maxDate: string}
   */
  public ticketDates: Signal<{minDate: string, maxDate: string}> = computed(() => {
    
    // Devuelve un objeto con las fechas mínima y máxima de los tickets.
    const dates: {minDate: Date | undefined, maxDate: Date | undefined } = this.tickets().reduce((acc, ticket) => {
    
      const ticketDate: Date = new Date(ticket.date);
      
      if(!acc.minDate || ticketDate < acc.minDate){
        acc.minDate = ticketDate;
      }

      if(!acc.maxDate || ticketDate > acc.maxDate){
        acc.maxDate = ticketDate;
      }

      return acc;

      }, 
      { minDate: undefined as Date | undefined, maxDate: undefined as Date | undefined }
    );

    // Retorna el objeto dates formateado a string.
    return {
      minDate: dates.minDate ? dates.minDate.toISOString().split('T')[0] : '',
      maxDate: dates.maxDate ? dates.maxDate.toISOString().split('T')[0] : ''
    }

  });

  // FILTERS
  /**
   * Formulario de filtro de fechas.
   */
  public dateFilter: FormGroup = new FormGroup({
    dateTo:   new FormControl<Date|undefined>(undefined),
    dateFrom: new FormControl<Date|undefined>(undefined),
    orderId:  new FormControl<string|undefined>(undefined)
  });

  // GETTERS
  public get disableClearFilters(): boolean {
    return this.dateFilter.pristine;
  }

  // LIFECYCLE HOOKS
  ngOnInit(): void {
    this.initComponent();
  }

  // METHODS
  /**
   * Aplica los filtros de búsqueda de tickets.
   * Puede buscarse por fecha (desde - hasta) o por el id del ticket. 
   * 
   * Para ello, hace una pequeña validación de los campos del formulario para
   * determinar qué tipo de búsqueda se realizará y si se han modificado los campos.
   * 
   * Si existe un id de ticket, se filtra por id. En caso contrario, se filtra por fechas.
   * El objeto options se construye con las fechas de inicio y fin si existen, si el valor del
   * campo es null, el objeto no tendrá la propiedad y por ende no se enviará en la petición.
   * 
   * @returns 
   */
  public applyFilters(): void {
    
    // Validaciones
    if(this.dateFilter.pristine && this.dateFilter.untouched){
      return;
    }

    // Filtrando por Order Id
    if(this.dateFilter.get('orderId')?.value){
      
      const id: string = this.dateFilter.get('orderId')?.value;
      
      this.orderHistoryService.getOrderHistory({order_id: id});

      return;
    }

    // Filtrando por fechas
    const date_to:   string = this.dateFilter.get('dateTo')?.value,
          date_from: string = this.dateFilter.get('dateFrom')?.value,
          options:   {date_to?: string, date_from?: string} = {
            ...(date_to   !== null ? {date_to} : {}),
            ...(date_from !== null ? {date_from} : {})
          };
    
    this.orderHistoryService.getOrderHistory(options);

  }

  /**
   * Limpia los filtros de búsqueda y vuelve a cargar los
   * tickets de la página actual.
   */
  public clearFilters(): void {
    this.dateFilter.reset();
    this.orderHistoryService.getOrderHistory(null, true);
  }

  /**
   * Método que se encarga de obtener el detalle de un ticket en específico.
   * Si no lo encuentra en el estado, realiza una petición al servicio.
   * @param orderId 
   * @returns 
   */
  public getOrderDetail(orderId: string): void {
    const key: string = orderId.split('_')[1] ?? orderId;

    if( this.orders().has(key) ){
      this.order.set(this.orders().get(key));
      this.orderView.fire();
      return;
    }

    this.orderHistoryService.getOrder(orderId).subscribe({
      next:  (order) => {
        this.order.set(order); 
        this.orderView.fire();
      },
      error: (error) => this.modalService.createErrorModal(error.message)
    })

  }
  
  /**
   * Inicializa el componente.
   * Obtiene el historial de tickets al cargar el componente.
   */
  private initComponent(): void {
    this.orderHistoryService.getOrderHistory();
  }
}

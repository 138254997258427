import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderDetail } from '../order-history.model';

@Component({
  selector: 'app-order-history-item',
  templateUrl: './order-history-item.component.html',
  styleUrl: './order-history-item.component.css'
})
export class OrderHistoryItemComponent {

  // INPUTS
  @Input({required: true}) order!: OrderDetail;

  // OUTPUTS
  @Output()
  orderDetail: EventEmitter<string> = new EventEmitter<string>();

  // METHODS
  public visitOrder(): void {
    this.orderDetail.emit(this.order.order_id);
  }

}

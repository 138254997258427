<!-- ORDER ITEM -->
<div class="mx-auto flex flex-col items-center justify-center py-3 gap-y-3 md:flex-row md:justify-between md:items-start lg:py-6 lg:max-w-4xl xl:max-w-6xl border-b">
                    
    <!-- DATE -->
    <div class="flex flex-col items-center md:items-start lg:w-1/4">
        
        <p class="block text-secondary mb-1">
            Date & Time
        </p>

        <p class="font-semibold text-darkgray">
            <span class="md:hidden">
                {{ order.date | date: 'd/M/yy' }}
                <br>
                {{ order.date | date: 'h:mm a' }} 
            </span>
            <span class="hidden md:block">
                {{ order.date | date: 'd/M/yy h:mm a' }} 
            </span>
        </p>
        
    </div>

    <!-- ORDER ID -->
    <div class="flex flex-col items-center md:items-start lg:w-1/4">
        <p class="block text-secondary mb-1">
            Order Id
        </p>
        <p class="font-semibold text-darkgray">
            {{ order.order_id }}
        </p>
    </div>

    <!-- BUTTON -->
    <div class="flex w-1/3 sm:w-1/4 md:w-1/5 self-center">

        <button class="button" (click)="visitOrder()">
            See Order
        </button>

    </div>

</div>
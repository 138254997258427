<section>

    <!-- MAIN CONTAINER AND FILTERS -->
    <div class="container mx-auto px-3 lg:px-0 lg:py-5 lg:max-w-4xl xl:max-w-6xl">

        <!-- TITLE -->
        <h4 class="text-secondary text-2xl my-5"> Filter Orders </h4>

        <!-- FILTER FORM -->
        <form class="form" [formGroup]="dateFilter">
            <div class="flex flex-col gap-2 md:gap-x-2 lg:gap-0 md:flex-row w-full">
                
                <!-- DATE FROM -->
                <div class="flex flex-col w-full lg:w-1/3 lg:mr-5">
                    <label for="dateFrom" class="text-secondary">
                        By Date From
                    </label>
                    <input formControlName="dateFrom" type="date" id="dateFrom" class="input" placeholder="dd/mm/yy" [min]="ticketDates().minDate" [max]="ticketDates().maxDate" >
                </div>

                <!-- DATE TO -->
                <div class="flex flex-col w-full lg:w-1/3 lg:mr-5">
                    <label for="dateTo" class="text-secondary">
                        To
                    </label>
                    <input formControlName="dateTo" type="date" id="dateTo" class="input" placeholder="Order Date" [min]="ticketDates().minDate" [max]="ticketDates().maxDate">
                </div>
                
                <!-- ORDER ID -->
                <div class="flex flex-col w-full lg:w-1/3">
                    <label for="order-id" class="text-secondary">
                        By Order ID
                    </label>
                    <input formControlName="orderId" type="text" id="orderId" class="input" placeholder="Order Id" maxlength="15">
                </div>
            </div>
        </form>

        <!-- ACTION BUTTONS -->
        <div class="w-full flex justify-end gap-x-2 my-2 lg:my-4">
            
            <!-- CLEAR FILTER -->
            <button (click)="clearFilters()" class="inline-block button lg:w-1/6" [disabled]="disableClearFilters">
                Clear Filter
            </button>

            <!-- APPLY FILTER -->
            <button (click)="applyFilters()" class="inline-block button lg:w-1/6">
                Apply Filter
            </button>

        </div>

    </div>

    <!-- RESULT VIEW -->
    <ng-container *ngIf="orderHistory() else loader">

        <!-- ORDER LIST -->
        <ng-container *ngIf="tickets().length else noTickets">
            
            <section class="bg-darkgray/5 w-full pb-10 px-3">
                <!-- ORDER ITEMS -->
                <app-order-history-item
                    [order]="order" 
                    (orderDetail)="getOrderDetail($event)"
                    *ngFor="let order of tickets()"
                />

                <!-- CONTROLS -->
                <ng-container *ngIf="orderHistory()?.count! > 1">
                    
                    <div class="flex justify-between h-10 items-center max-w-4xl mx-auto font-main">
                        
                        <!-- PREV BUTTON -->
                        <div class="w-1/6">
                            <button class="text-secondary font-medium hover:scale-105 transition-transform disabled:hover:scale-100 disabled:text-gray-500 origin-center" (click)="orderHistoryService.previous()" [disabled]="page() === 1">
                                Previous
                            </button>
                        </div>

                        <!-- PAGE NUMBER -->
                        <div class="w-1/6">
                            <p class="text-center text-darkgray">
                                Page
                                <span class="text-main font-medium">
                                    {{ page() }}
                                </span> 
                                of
                                <span class="text-main font-medium">
                                    {{ orderHistory()?.count! }}
                                </span>
                            </p>
                        </div>

                        <!-- NEXT BUTTON -->
                        <div class="w-1/6">
                            <button class="text-secondary font-medium hover:scale-105 transition-transform disabled:hover:scale-100 disabled:text-gray-500 origin-center" (click)="orderHistoryService.next()" [disabled]="page() >= orderHistory()?.count!">
                                Next
                            </button>
                        </div>
    
                    </div>

                </ng-container>

            </section>

        </ng-container>

        <!-- NO TICKETS -->
        <ng-template #noTickets>
            <div class="container mx-auto">
                <p class="text-secondary text-center my-5">
                    No tickets found
                </p>
            </div>
        </ng-template>
        
        <!-- MODAL -->
        <swal 
            title="orderView" 
            [customClass]="{popup: 'w-[32rem] lg:w-[40vw]'}"
            [showConfirmButton]="false" 
            #orderView
        >
            <!-- HEADER -->
            <div *swalPortal="swalTargets.title" class="w-full bg-main text-white h-16 lg:h-20 flex items-center justify-center rounded-md">
                <h4 class="text-2xl lg:text-3xl font-normal uppercase"> Order Confirmed </h4>
            </div>

            <!-- CONTENT -->
            <div *swalPortal="swalTargets.content" class="text-left px-1 md:px-2 font-main text-sm max-h-[55vh] overflow-y-auto">
                <app-order-detail [order]="order()!" [orderType]="orderType()!"/>
            </div>

            <!-- FOOTER -->
            <div *swalPortal="swalTargets.footer">
                <button class="button w-1/4" (click)="orderView.close()">
                    Close
                </button>
            </div>

        </swal>

    </ng-container>

    <!-- LOADER -->
    <ng-template #loader>
        <div class="container mx-auto flex items-center justify-center pt-2">
            <app-loader/>
        </div>
    </ng-template>

</section>
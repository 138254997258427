import { HttpClient, HttpParams } from '@angular/common/http';
import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { OrderHistory } from 'src/app/views/order-history/order-history.model';
import { Observable, tap } from 'rxjs';
import { ExchangeOrder, Order, PackageOrder, ReservationOrder, SaleOrder } from 'src/app/shared/models/order.model';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  // SERVICES
  private http: HttpClient = inject(HttpClient);

  // ENDPOINT
  private endpoint: string = "/customer/order/";

  // STATE
  private _orderHistory: WritableSignal<OrderHistory | undefined> = signal(undefined);
  private _orders:       WritableSignal<Map<string, Order>>       = signal(new Map());
  private _page:         WritableSignal<number>                   = signal(1);

  // COMPUTED
  public  page:          Signal<number>                           = computed(() => this._page());
  public  orderHistory:  Signal<OrderHistory | undefined>         = computed(() => this._orderHistory());
  public  orders:        Signal<Map<string, Order>>               = computed(() => this._orders());
  public  tickets:       Signal<OrderHistory['results']>          = computed(() => this.orderHistory()?.results ?? []);

  // METHODS
  public getOrderHistory(options?: {date_to?: string, date_from?: string} | {order_id: string} | null, reset?: boolean): void {

    if(reset){
      this.resetOrderHistory();
    }

    const optionsParams: HttpParams = options ? 
      new HttpParams({fromObject: options}) : 
      new HttpParams();
  
    const defaultParams: HttpParams = optionsParams.set('page', this.page().toString());

    this.http.get<OrderHistory>(this.endpoint + 'history/', { params: defaultParams }).subscribe({
      next:  (orderHistory) => this._orderHistory.set(orderHistory),
      error: (error) => console.error(error)
    });

  }

  public getOrder(id: string): Observable<SaleOrder | ReservationOrder | PackageOrder | ExchangeOrder> {
    return this.http.get<SaleOrder | ReservationOrder | PackageOrder | ExchangeOrder>(this.endpoint + id + '/').pipe(
      tap((order) =>  this._orders.update(orders => orders.set(order.order_id.split('_')[1] ?? order.order_id, order))),
    );
  }

  public next(): void {
    if(this.orderHistory()?.count! > this._page()){
      this._page.update(page => page + 1);
      this.getOrderHistory();
      window.scrollTo(0, 0);
    }
  }

  public previous(): void {
    if(this._page() > 1 && this.orderHistory()?.count! > 1){
      this._page.update(page => page - 1);
      this.getOrderHistory();
    }
  }

  private resetOrderHistory(): void {
    this._orderHistory.set(undefined);
    this._page.set(1);
  }

}
